<script>
import Layout from "@/views/layouts/horizontal.vue";
import appConfig from "@/app.config";
import ax from '@/helpers/restful/service';

import AlertModal from "@/components/alert-modal.vue";
import ConfirmModal from "@/components/confirm-modal.vue";

export default {
  page: {
    title: "ระบบหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    AlertModal,
    ConfirmModal,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "Thaimed",
        },
        {
          text: "ข้อมูลส่วนตัว",
          active: true,
        },
      ],
      uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid,
      loaded: true,
      create_data: {
        course_inspection_med_type_id: null,
        course_inspection_uid: null,
        course_inspection_name: null,
        course_inspection_validate_registered_user: null,
        course_inspection_validate_listed_user: null,
        course_inspection_validate_verified_user: null,
        course_inspection_validate_certified_user: null,
        course_inspection_file: null,
      },
      file_upload_url: null,
      med_type: [],
      my_courses_inspection: [],
      can_save: false,
      isShowConfirmModal: false,
      isSaveSuccessModal: false,
      isSaveFailedModal: false,
      isNoticeModal: false,
      noticeTitleMsg: '',
      noticeTextMsg: '',
    };
  },
  computed: {},
  watch: {
    create_data: {
      handler: function (val) {
        if (val.course_inspection_med_type_id != null &&
          val.course_inspection_med_type_id != undefined &&
          val.course_inspection_uid != null &&
          val.course_inspection_uid != undefined &&
          val.course_inspection_name != null &&
          val.course_inspection_name != undefined &&
          val.course_inspection_validate_registered_user != null &&
          val.course_inspection_validate_registered_user != undefined &&
          val.course_inspection_validate_listed_user != null &&
          val.course_inspection_validate_listed_user != undefined &&
          val.course_inspection_validate_verified_user != null &&
          val.course_inspection_validate_verified_user != undefined &&
          val.course_inspection_validate_certified_user != null &&
          val.course_inspection_validate_certified_user != undefined &&
          val.course_inspection_file != null &&
          val.course_inspection_file != undefined
        ) {
          this.can_save = true;
          return;
        }
        this.can_save = false;
      },
      deep: true,
    },
  },
  async mounted() {
    document.body.classList.add('authentication-bg');
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loaded = false;
      this.create_data = {
        course_inspection_med_type_id: null,
        course_inspection_uid: null,
        course_inspection_name: null,
        course_inspection_validate_registered_user: null,
        course_inspection_validate_listed_user: null,
        course_inspection_validate_verified_user: null,
        course_inspection_validate_certified_user: null,
        course_inspection_file: null,
      };
      this.file_upload_url = null;
      this.med_type = [];
      this.can_save = false;
      this.isShowConfirmModal = false;
      this.isSaveSuccessModal = false;
      this.isSaveFailedModal = false;

      const r = await Promise.all([
        this.getMedType(),
        this.getUserInfo(),
        this.getMyCoursesInspection(),
      ]);
      if (r.every(e => e)) {
        this.loaded = true;
        return;
      }
      console.log('loadData error:', r);
    },
    async fileUploadSelect(evt) {
      console.log(evt);
      console.log('fileUploadSelect');
      if (evt.target.files.length > 0) {
        const file = evt.target.files[0];
        console.log('file:', file);
        if (file.type != 'application/pdf') {
          alert('ไฟล์ต้องเป็น PDF เท่านั้น');
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        const resp = await ax.postForm("/upload-pdf-file", formData);
        console.log("resp fileupload:", resp);
        if (resp.file_name) {
          this.file_upload_url = resp.file_name;
          this.create_data.course_inspection_file = resp.file_name;
          return;
        }
        this.file_upload_url = null;
        this.create_data.course_inspection_file = null;
        return;
      }
    },
    async getUserInfo() {
      const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
      console.log("resp:", resp);
      if (resp.status != 200) {
        return false;
      }
      this.currentUser = resp.data;
      console.log("currentUser:", this.currentUser);
      this.create_data.course_inspection_uid = 'VS' + this.currentUser.uid.toString().padStart(6, '0');
      
      return true;
    },
    async getMedType() {
      const resp = await ax.post("/get-med-type", {});
      console.log("med type:", resp);

      if (resp.status == 200) {
        this.med_type = resp.data.med_type.map((e) => {
          return {
            value: e.med_type_id,
            text: e.med_type_name,
          };
        });
        return true;
      }
      this.med_type = [];
      return false;
    },
    async getMyCoursesInspection() {
      const resp = await ax.post("/get-my-courses-inspection", { uid: this.uid });
      console.log("my courses inspection:", resp);

      if (resp.status == 200) {
        this.my_courses_inspection = resp.data.my_courses_inspection;
        return true;
      }
      this.my_courses_inspection = [];
      return false;
    },
    async confirmCourseInspectionRequest() {
      if (
        !(
          this.create_data.course_inspection_uid != null && this.create_data.course_inspection_uid != undefined && this.create_data.course_inspection_uid != '' &&
          this.create_data.course_inspection_uid.toLowerCase() != this.create_data.course_inspection_validate_registered_user.toLowerCase() &&
          this.create_data.course_inspection_validate_listed_user.toLowerCase() != this.create_data.course_inspection_validate_registered_user.toLowerCase() &&
          this.create_data.course_inspection_validate_verified_user.toLowerCase() != this.create_data.course_inspection_validate_registered_user.toLowerCase() &&
          this.create_data.course_inspection_validate_certified_user.toLowerCase() != this.create_data.course_inspection_validate_registered_user.toLowerCase()
        )
      ) {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'ผู้รับรองการตรวจสอบ ต้องเป็นต้องไม่ซ้ำกับผู้รับรองอื่นๆในคำขอเดียวกัน';
        this.isNoticeModal = true;
        return;
      }

      if (this.create_data.course_inspection_file == null || this.create_data.course_inspection_file == undefined || this.create_data.course_inspection_file == '') {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'กรุณาเลือกไฟล์คำขอ';
        this.isNoticeModal = true;
        return;
      }

      if (this.create_data.course_inspection_med_type_id == null || this.create_data.course_inspection_med_type_id == undefined || this.create_data.course_inspection_med_type_id == '') {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'กรุณาเลือกชื่อทะเบียน';
        this.isNoticeModal = true;
        return;
      }

      if (this.create_data.course_inspection_validate_registered_user == null || this.create_data.course_inspection_validate_registered_user == undefined || this.create_data.course_inspection_validate_registered_user == '') {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'กรุณากรอกผู้รับรองการจดทะเบียน';
        this.isNoticeModal = true;
        return;
      }

      if (this.create_data.course_inspection_validate_listed_user == null || this.create_data.course_inspection_validate_listed_user == undefined || this.create_data.course_inspection_validate_listed_user == '') {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'กรุณากรอกผู้รับรองการขึ้นทะเบียน';
        this.isNoticeModal = true;
        return;
      }

      if (this.create_data.course_inspection_validate_verified_user == null || this.create_data.course_inspection_validate_verified_user == undefined || this.create_data.course_inspection_validate_verified_user == '') {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'กรุณากรอกผู้รับรองการตรวจสอบ';
        this.isNoticeModal = true;
        return;
      }

      if (this.create_data.course_inspection_validate_certified_user == null || this.create_data.course_inspection_validate_certified_user == undefined || this.create_data.course_inspection_validate_certified_user == '') {
        this.noticeTitleMsg = 'ข้อมูลไม่ถูกต้อง';
        this.noticeTextMsg = 'กรุณากรอกผู้รับรองการตรวจรับรอง';
        this.isNoticeModal = true;
        return;
      }

      this.isShowConfirmModal = true;
    },
    async saveCourseInspectionRequest() {
      const data = {
        course_inspection_med_type_id: this.create_data.course_inspection_med_type_id,
        course_inspection_uid: parseInt(this.create_data.course_inspection_uid.replace('VS', '')),
        course_inspection_name: this.create_data.course_inspection_name,
        course_inspection_validate_registered_user: parseInt(this.create_data.course_inspection_validate_registered_user.replace('VS', '')),
        course_inspection_validate_listed_user: parseInt(this.create_data.course_inspection_validate_listed_user.replace('VS', '')),
        course_inspection_validate_verified_user: parseInt(this.create_data.course_inspection_validate_verified_user.replace('VS', '')),
        course_inspection_validate_certified_user: parseInt(this.create_data.course_inspection_validate_certified_user.replace('VS', '')),
        course_inspection_file: this.create_data.course_inspection_file,
      }

      console.log("data:", data);

      const resp = await ax.post("/save-course-inspection", data);
      console.log("save :", resp);

      if(resp.data.is_created){
        this.isSaveSuccessModal = true;
        return;
      }
      this.isSaveFailedModal = true;
      return;
    },
    closeConfirmModal() {
      this.isShowConfirmModal = false;
    },
  },
};
</script>

<template>
  <div>
    <Layout layout="horizontal" v-if="loaded">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-5 table-responsive">
            <table class="table text-center">
              <thead>
                <tr>
                  <th scope="col" class="bg-success text-white">แบบคำขอขึ้นทะเบียน</th>
                  <th scope="col" class="bg-success text-white">ข้อมูลผู้ยื่นคำขอ</th>
                  <th scope="col" class="bg-success text-white">ผู้รับรองการจดทะเบียน</th>
                  <th scope="col" class="bg-success text-white">ผู้รับรองการขึ้นทะเบียน</th>
                  <th scope="col" class="bg-success text-white">ผู้รับรองการตรวจสอบ</th>
                  <th scope="col" class="bg-success text-white">ผู้รับรองการตรวจรับรอง</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in my_courses_inspection" :key="index">
                  <td>
                    <a :href="item.course_inspection_file" target="_blank">
                      แบบคำขอ #{{ item.course_inspection_id }}<br/>{{ item.med_type_name }}
                    </a>
                  </td>
                  <td>{{ 'VS' + item.course_inspection_uid.toString().padStart(6, '0') }} {{ item.course_inspection_name }}</td>
                  <td>
                    {{ 'VS' + item.course_inspection_validate_registered_user.toString().padStart(6, '0') }}<br/>
                    สถานะ: <br/>
                    <span :class="item.course_inspection_validate_registered_dt == null ? 'text-warning' : 'text-success'">{{ item.course_inspection_validate_registered_dt == null ? 'รอผลตรวจ' : 'ผ่าน @ '+ new Date(item.course_inspection_validate_registered_dt).toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</span>
                  </td>
                  <td>
                    {{ 'VS' + item.course_inspection_validate_listed_user.toString().padStart(6, '0') }}<br/>
                    สถานะ: <br/>
                    <span :class="item.course_inspection_validate_listed_dt == null ? 'text-warning' : 'text-success'">{{ item.course_inspection_validate_listed_dt == null ? 'รอผลตรวจ' : 'ผ่าน @ '+ new Date(item.course_inspection_validate_listed_dt).toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</span>
                  </td>
                  <td>
                    {{ 'VS' + item.course_inspection_validate_verified_user.toString().padStart(6, '0') }}<br/>
                    สถานะ: <br/>
                    <span :class="item.course_inspection_validate_verified_dt == null ? 'text-warning' : 'text-success'">{{ item.course_inspection_validate_verified_dt == null ? 'รอผลตรวจ' : 'ผ่าน @ '+ new Date(item.course_inspection_validate_verified_dt).toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</span>
                  </td>
                  <td>
                    {{ 'VS' + item.course_inspection_validate_certified_user.toString().padStart(6, '0') }}<br/>
                    สถานะ: <br/>
                    <span :class="item.course_inspection_validate_certified_dt == null ? 'text-warning' : 'text-success'">{{ item.course_inspection_validate_certified_dt == null ? 'รอผลตรวจ' : 'ผ่าน @ '+ new Date(item.course_inspection_validate_certified_dt).toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="bg-success text-white">ยื่นคำขอขึ้นทะเบียน</th>
                  <th scope="col" class="bg-success text-white">ข้อมูล</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ชื่อทะเบียน (กดเพื่อดูเกณฑ์รายละเอียด)</td>
                  <td>
                    <select class="form-control" v-model="create_data.course_inspection_med_type_id">
                      <option v-for="(item, index) in med_type" :value="item.value" :key="index">
                        {{ item.text }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>รหัสผู้ยื่นคำขอ</td>
                  <td>
                    <input type="text" class="form-control" placeholder="รหัสผู้ยื่นคำขอ" readonly="true"
                      v-model="create_data.course_inspection_uid" />
                  </td>
                </tr>
                <tr>
                  <td>ชื่อผู้ยื่นคำขอ</td>
                  <td>
                    <input type="text" class="form-control" placeholder="ชื่อผู้ยื่นคำขอ"
                      v-model="create_data.course_inspection_name" />
                  </td>
                </tr>
                <tr>
                  <td>ผู้รับรองการจดทะเบียน (Registered)</td>
                  <td>
                    <input type="text" class="form-control" placeholder="VSXXXXXX"
                      v-model="create_data.course_inspection_validate_registered_user" />
                  </td>
                </tr>
                <tr>
                  <td>ผู้รับรองการขึ้นทะเบียน (Listed)</td>
                  <td>
                    <input type="text" class="form-control" placeholder="VSXXXXXX"
                      v-model="create_data.course_inspection_validate_listed_user" />
                  </td>
                </tr>
                <tr>
                  <td>ผู้รับรองการตรวจสอบ (Verified)</td>
                  <td>
                    <input type="text" class="form-control" placeholder="VSXXXXXX"
                      v-model="create_data.course_inspection_validate_verified_user" />
                  </td>
                </tr>
                <tr>
                  <td>ผู้รับรองการตรวจรับรอง (Certified)</td>
                  <td>
                    <input type="text" class="form-control" placeholder="VSXXXXXX"
                      v-model="create_data.course_inspection_validate_certified_user" />
                  </td>
                </tr>
                <tr>
                  <td>ไฟล์คำขอ (PDF เท่านั้น)</td>
                  <td>
                    <input type="file" class="form-control" placeholder="VSXXXXXX" @change="fileUploadSelect"
                      accept="application/pdf" />
                    <div v-if="file_upload_url" class="mt-2">
                      <a :href="file_upload_url" target="_blank">{{ file_upload_url }}</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div class="d-grid">
                      <button class="btn btn-lg btn-primary" type="button" :disabled="!can_save"
                        @click="confirmCourseInspectionRequest">ส่งคำขอ</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="my-5">

            </div>
          </div>
        </div>
      </div>
    </Layout>
    <ConfirmModal :isShow="isShowConfirmModal" v-on:close="isShowConfirmModal = false;" v-on:confirm="isShowConfirmModal = false; saveCourseInspectionRequest();" title="ยืนยันการบันทึกคำขอ" text="คำขอจะไม่สามารถแก้ไข หรือเปลี่ยนแปลงได้หลังจากส่งคำขอไปแล้ว">
    </ConfirmModal>

    <AlertModal :isShow="isSaveSuccessModal" title="ส่งคำขอสำเร็จ"
            text="ข้อมูลคำขอถูกบันทึกแล้ว..." v-on:close="isSaveSuccessModal = false; loadData();"></AlertModal>
    <AlertModal :isShow="isSaveFailedModal" title="ส่งคำขอไม่สำเร็จ"
            text="กรุณาลองใหม่อีกครั้ง..." v-on:close="isSaveFailedModal = false"></AlertModal>

    <AlertModal :isShow="isNoticeModal" :title="noticeTitleMsg"
            :text="noticeTextMsg" v-on:close="isNoticeModal = false"></AlertModal>

  </div>
</template>